export default [
  // {
  //   title: "Dash [locked]",
  //   icon: "mdi-view-dashboard-outline",
  //   to: "/"
  // },
  {
    title: "Data Source",
    icon: "mdi-details",
    to: "/datasources/1660934",
  },
  {
    title: "Reports",
    icon: "mdi-file-document",
    to: "/reports",
  },
  {
    title: "Rentals",
    icon: "mdi-package",
    to: "/rentals",
  },
  {
    title: "Tickets",
    icon: "mdi-ticket",
    to: "/tickets",
  },
  {
    title: "Documents",
    icon: "mdi-file-document",
    to: "/documents",
  },
  {
    title: "Hardware",
    icon: "mdi-wrench",
    to: "/hardware",
  },
  {
    title: "UNS",
    icon: "mdi-hexagon-multiple",
    to: "/uns",
  },
  // {
  //   title: "Alerts",
  //   icon: "mdi-alert-circle-outline",
  //   to: "/alerts",
  // },
  // {
  //   title: "Buttons",
  //   icon: "mdi-radiobox-blank",
  //   to: "/buttons",
  // },
  // {
  //   title: "Cards",
  //   icon: "mdi-card-outline",
  //   to: "/cards",
  // },
  // {
  //   title: "Carousel",
  //   icon: "mdi-form-dropdown",
  //   to: "/carousel",
  // }
];
